import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image, Transformer } from 'react-konva';
import { Download, Plus, Check, Trash2, RefreshCw, ChevronLeft, Loader, Share } from 'lucide-react';

// Define fixed high-resolution canvas size
const CANVAS_VIRTUAL_WIDTH = 1080;
const CANVAS_VIRTUAL_HEIGHT = 1080; // Keep it square for this use case

const ImageEditor = () => {
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [canvasElements, setCanvasElements] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [currentView, setCurrentView] = useState('collections');
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [loadingElement, setLoadingElement] = useState(null);
  const [collections, setCollections] = useState([]);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [fadeOut, setFadeOut] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [imageLoadingStates, setImageLoadingStates] = useState({});

  const stageRef = useRef(null);
  const trRef = useRef(null);
  const elementsContainerRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetch('/images/directory.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log("Fetched directory data:", data);
        const formattedCollections = Object.entries(data).map(([collectionName, elements]) => ({
          title: collectionName.replace(/\*_/g, ' '),
          cover: `/images/${collectionName}/cover.png`,
          elements: elements.map(filename => ({
            name: filename.replace(/\.png$/, '').replace(/\*_/g, ' '),
            src: `/images/${collectionName}/${filename}`,
          })),
        }));
        console.log("Formatted collections:", formattedCollections);
        setCollections(formattedCollections);
      })
      .catch(error => {
        console.error("Error fetching directory structure:", error);
        console.error("Error details:", error.message);
      });
  }, []);

  useEffect(() => {
    if (selectedId && trRef.current) {
      const selectedNode = stageRef.current.findOne(`#${selectedId}`);
      if (selectedNode) {
        trRef.current.nodes([selectedNode]);
        trRef.current.getLayer().batchDraw();
      }
    } else if (trRef.current) {
      trRef.current.nodes([]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selectedId]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new window.Image();
      img.src = e.target.result;
      img.onload = () => {
        setBackgroundImage(img);
        // Adjust stage size if needed
      };
    };
    reader.readAsDataURL(file);
  };

  const addElement = (element) => {
    const img = new window.Image();
    img.src = element.src;
    img.onload = () => {
      const canvasSize = calculateCanvasSize();
      const maxSize = canvasSize * 0.8;
      let newWidth = img.width;
      let newHeight = img.height;
      const aspectRatio = img.width / img.height;

      if (newWidth > maxSize || newHeight > maxSize) {
        if (aspectRatio > 1) {
          newWidth = maxSize;
          newHeight = newWidth / aspectRatio;
        } else {
          newHeight = maxSize;
          newWidth = newHeight * aspectRatio;
        }
      }

      const newElement = {
        ...element,
        id: Date.now().toString(),
        x: (canvasSize - newWidth) / 2,
        y: (canvasSize - newHeight) / 2,
        width: newWidth,
        height: newHeight,
        image: img,
      };
      setCanvasElements(prevElements => [...prevElements, newElement]);
      selectShape(newElement.id);
    };
  };

  const handleSelect = (id) => {
    selectShape(id);
  };

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const resetCanvas = () => {
    setBackgroundImage(null);
    setCanvasElements([]);
    selectShape(null);
  };

  const downloadImage = async () => {
    console.log('Starting downloadImage function');

    let selectedNode = null;
    if (trRef.current) {
      selectedNode = trRef.current.nodes()[0];
      trRef.current.nodes([]);
      trRef.current.getLayer().batchDraw();
    }

    const stage = stageRef.current;
    const stageWidth = stage.width();
    const stageHeight = stage.height();

    // Get background image size
    const bgWidth = backgroundImage ? backgroundImage.width : CANVAS_VIRTUAL_WIDTH;
    const bgHeight = backgroundImage ? backgroundImage.height : CANVAS_VIRTUAL_HEIGHT;

    // Use the background image size for the output
    const outputWidth = bgWidth;
    const outputHeight = bgHeight;
    console.log('Output size:', { width: outputWidth, height: outputHeight });

    // Create a new off-screen canvas
    const canvas = document.createElement('canvas');
    canvas.width = outputWidth;
    canvas.height = outputHeight;
    const ctx = canvas.getContext('2d');

    // Draw background
    if (backgroundImage) {
      ctx.drawImage(backgroundImage, 0, 0, outputWidth, outputHeight);
    } else {
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, outputWidth, outputHeight);
    }

    // Calculate scale factor
    const scaleFactor = outputWidth / stageWidth;

    // Scale and draw the stage content
    const stageCanvas = stage.toCanvas({
      pixelRatio: scaleFactor,
      width: stageWidth,
      height: stageHeight,
    });
    ctx.drawImage(stageCanvas, 0, 0, outputWidth, outputHeight);

    console.log('Stage drawn to high-res canvas');

    // Get the data URL
    const dataURL = canvas.toDataURL('image/png');
    console.log('Data URL generated');

    // Sharing or downloading logic
    if (navigator.share && windowSize.width < 768) {
      console.log('Attempting to share on mobile');
      try {
        const blob = await (await fetch(dataURL)).blob();
        const file = new File([blob], 'younes-pfp.png', { type: 'image/png' });
        await navigator.share({
          files: [file],
          title: 'Younes PFP',
          text: 'Check out my custom PFP!',
        });
      } catch (error) {
        console.error('Error sharing:', error);
        // Fallback to download if sharing fails
        downloadFile(dataURL);
      }
    } else {
      console.log('Downloading image');
      downloadFile(dataURL);
    }

    console.log('downloadImage function completed');

    // Restore the transformer
    if (selectedNode && trRef.current) {
      trRef.current.nodes([selectedNode]);
      trRef.current.getLayer().batchDraw();
    }
  };

  // Helper function to download the file
  const downloadFile = (dataURL) => {
    const link = document.createElement('a');
    link.download = 'younes-pfp.png';
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCollectionClick = (collection) => {
    setSelectedCollection(collection);
    setCurrentView('elements');
    // Scroll the elements container to the top
    if (elementsContainerRef.current) {
      elementsContainerRef.current.scrollTop = 0;
    }
  };

  const handleBackToCollections = () => {
    setCurrentView('collections');
    setSelectedCollection(null);
  };

  const handleElementClick = (element) => {
    setLoadingElement(element.name);
    setFadeOut(false);
    addElement(element);
    setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setLoadingElement(null);
        setFadeOut(false);
      }, 1000);
    }, 2000);
  };

  const deleteSelectedElement = () => {
    setCanvasElements(prevElements => prevElements.filter(elem => elem.id !== selectedId));
    selectShape(null);
  };

  const calculateCanvasSize = () => {
    const containerWidth = windowSize.width * (windowSize.width < 768 ? 0.9 : 0.65);
    const containerHeight = window.innerHeight * (windowSize.width < 768 ? 0.5 : 0.6);
    return Math.min(containerWidth, containerHeight);
  };

  const canvasSize = calculateCanvasSize();

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <div className="flex flex-col md:flex-row flex-grow overflow-hidden">
        <div className="w-full md:w-2/3 p-4 flex flex-col" ref={containerRef}>
          <div 
            className={`flex-grow bg-black flex justify-center items-center border border-purple-700 rounded-lg transition-all duration-300 ${isHovering && !backgroundImage ? 'border-purple-500' : ''}`}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {backgroundImage ? (
              <Stage
                width={canvasSize}
                height={canvasSize}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
                ref={stageRef}
              >
                <Layer>
                  <Image
                    image={backgroundImage}
                    width={canvasSize}
                    height={canvasSize}
                  />
                  {canvasElements.map((elem) => (
                    <React.Fragment key={elem.id}>
                      <Image
                        id={elem.id}
                        image={elem.image}
                        x={elem.x}
                        y={elem.y}
                        width={elem.width}
                        height={elem.height}
                        rotation={elem.rotation || 0}
                        draggable
                        onClick={() => handleSelect(elem.id)}
                        onTap={() => handleSelect(elem.id)}
                        onTransformEnd={(e) => {
                          const node = e.target;
                          const scaleX = node.scaleX();
                          const scaleY = node.scaleY();
                          const rotation = node.rotation();

                          node.scaleX(1);
                          node.scaleY(1);
                          node.width(Math.max(5, node.width() * scaleX));
                          node.height(Math.max(5, node.height() * scaleY));

                          setCanvasElements(prevElements =>
                            prevElements.map(el =>
                              el.id === node.id()
                                ? {
                                    ...el,
                                    x: node.x(),
                                    y: node.y(),
                                    width: node.width(),
                                    height: node.height(),
                                    rotation: rotation,
                                  }
                                : el
                            )
                          );
                        }}
                      />
                      {selectedId === elem.id && (
                        <Transformer
                          ref={trRef}
                          boundBoxFunc={(oldBox, newBox) => {
                            if (newBox.width < 5 || newBox.height < 5) {
                              return oldBox;
                            }
                            return newBox;
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Layer>
              </Stage>
            ) : (
              <div className="flex flex-col items-center justify-center p-8 text-center max-w-md">
                <img src="/Logo.jpeg" alt="PFP Generator" className="mb-4 w-32 h-32" />
                <h1 className="text-white text-2xl mb-4 font-bold">Younes PFP Generator</h1>
                <p className="text-gray-400 mb-6 text-sm">Upload a background image to start creating your custom PFP. You can then add and customize elements from our collections.</p>
                <label className="bg-purple-700 text-white px-6 py-3 rounded-full cursor-pointer hover:bg-purple-600 transition-colors text-sm font-semibold">
                  <Plus className="inline mr-2" size={16} />
                  Upload Background Image
                  <input type="file" className="hidden" onChange={handleImageUpload} accept="image/*" />
                </label>
              </div>
            )}
          </div>
          {backgroundImage && (
            <div className="mt-4 flex justify-between p-4 bg-black rounded-lg">
              <div className="flex space-x-2">
                <button onClick={resetCanvas} className="bg-purple-700 text-white p-2 rounded-full hover:bg-purple-600 transition-colors">
                  <RefreshCw size={24} />
                </button>
                <button 
                  onClick={deleteSelectedElement} 
                  className="bg-purple-700 text-white p-2 rounded-full hover:bg-purple-600 transition-colors"
                  disabled={!selectedId}
                >
                  <Trash2 size={24} />
                </button>
              </div>
              <button onClick={downloadImage} className="bg-purple-700 text-white px-4 py-2 rounded-full hover:bg-purple-600 transition-colors flex items-center">
                {windowSize.width < 768 ? <Share className="mr-2" size={24} /> : <Download className="mr-2" size={24} />}
                <span className="font-bold">{windowSize.width < 768 ? 'Share' : 'Download'}</span>
              </button>
            </div>
          )}
        </div>
        <div className="w-full md:w-1/3 bg-black p-4 overflow-y-auto" ref={elementsContainerRef}>
          {currentView === 'collections' ? (
            <>
              <h2 className="text-white text-xl font-bold mb-4">Choose your style</h2>
              <div className="grid grid-cols-3 gap-4">
                {collections.map((collection) => (
                  <div
                    key={collection.title}
                    className="flex flex-col items-center justify-center cursor-pointer border border-purple-700 rounded-lg p-2 hover:bg-purple-900 transition-colors"
                    onClick={() => handleCollectionClick(collection)}
                  >
                    <img src={collection.cover} alt={collection.title} className="object-cover w-full h-24 rounded-lg" />
                    <span className="text-white text-sm mt-2 font-bold text-center">{collection.title}</span>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="grid grid-cols-3 gap-4">
              <div
                className="flex flex-col items-center justify-center cursor-pointer border border-purple-700 rounded-lg p-2 hover:bg-purple-900 transition-colors"
                onClick={handleBackToCollections}
              >
                <div className="w-full h-24 flex items-center justify-center">
                  <ChevronLeft size={24} className="text-white" />
                </div>
                <span className="text-white text-sm mt-2 font-bold">Back</span>
              </div>
              {selectedCollection.elements.map((element) => (
                <div
                  key={element.name}
                  className="flex flex-col items-center justify-center cursor-pointer border border-purple-700 rounded-lg p-2 hover:bg-purple-900 transition-colors relative"
                  onClick={() => handleElementClick(element)}
                >
                  {!imageLoadingStates[element.name] ? (
                    <div className="w-full h-24 flex items-center justify-center">
                      <Loader className="animate-spin text-purple-500" size={24} />
                    </div>
                  ) : null}
                  <img 
                    src={element.src} 
                    alt={element.name} 
                    className={`object-contain w-full h-24 rounded-lg ${!imageLoadingStates[element.name] ? 'hidden' : ''}`}
                    onLoad={() => setImageLoadingStates(prev => ({ ...prev, [element.name]: true }))}
                  />
                  <span className="text-white text-sm mt-2 text-center">{element.name}</span>
                  {loadingElement === element.name && (
                    <div className={`absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg transition-opacity duration-1000 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                      <div className="w-12 h-12 rounded-full bg-purple-700 flex items-center justify-center">
                        <Check className="text-white" size={24} />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;