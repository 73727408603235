import React from 'react';
import ImageEditor from './components/ImageEditor';

function App() {
  return (
    <div className="App">
      <ImageEditor />
    </div>
  );
}

export default App;